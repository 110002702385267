/* Global Styles */

@import url('https://fonts.googleapis.com/css2?family=Pattaya&family=Poppins:wght@400;500&display=swap');


/* Nav Section */

.main-head {
  background: #F2F2F2;
  color: white;
}


/* Hero Section */

.hero {
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent), url(./assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 43vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero h3 {
  padding: 3rem;
}

.hero .wrapper {
  width: 90%;
  margin: auto;
}

/* Featured Books and Books Section */

.featured-head {
  background: #fff;
  padding: 2rem;
  color: #343c44;
  display: flex;
  justify-content: center;
  align-items: center;
}

.books {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: center;
  width: 90%;
  margin: auto;
}

.book {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1), 0px 20px 20px rgba(0, 0, 0, 0.1);
  width: 22rem;
  height: 28rem;
  margin: 3rem 4rem;
  border-top: 1px solid #efefef;
  background: floralwhite;
}

.featured-list {
  justify-content: space-evenly;
}

.featured-book {
  margin: 1rem 0;
}

.book-image {
  flex: 3 1 100rem;
  padding: 2rem 0rem;
}

.book-link {
  flex: 1 1 100rem;
  background: #55606c;
  font-size: 1.7rem;
  width: 100%;
  height: 100%;
}

.book-link:hover {
  background: #343c43;
}

.book img {
  min-height: 10vh;
  height: 21vh;
}

/* Book Details Section */

.book-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
  height: 80vh;
  text-align: center
}

.detail-image {
  text-align: center;
}

.submit-buttom {
  background: #660000 !important;
  color: #FFFFFF !important;
}

.icon-button {
  background: transparent !important;
}
.detail-description {
  display: flex;
  flex: 1 1 50rem;
  flex-direction: column;
  justify-content: space-evenly;
  height: 75%;
  padding-top: 1rem;
}

/* Admin Section */

.admin-wrapper {
  width: 70%;
  margin: auto;
  text-align: center;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 3rem 0;
}

.form-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 1px #c7c7c7 solid;
  padding: 2rem;
}

.form-image {
  flex: 1 1 50rem;
}

.image-preview {
  width: 35rem;
}

.form-fields {
  flex: 3 1 50rem;
  text-align: left;
}

.form-fields input {
  width: 100%;
  height: 4rem;
  font-size: 2rem;
}

.form-fields textarea {
  width: 100%;
  font-size: 2rem;
}

.form-wrapper label {
  margin: 0rem 2rem;
  text-align: left;
}

.title-form,
.description-form,
.author-form,
.price-form,
.submit-form,
.featured-form {
  padding: 0.7rem;
}

.form-fields .featured-checkbox {
  width: 2rem;
  position: relative;
  top: 1.4rem;
}

.submit-form {
  width: 100%;
}

/* Checkout Section */

.checkout-wrapper {
  width: 60%;
  margin: auto;
  text-align: center;
}

.checkout-form {
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
}

.stripe-section {
  margin-top: 2rem;
}

.btn {
  color: white !important;
  border: black 1px !important;
  background: #CE4257 !important;
}

.panel {
  background: #f2f2f2;
  /* // background: #313639; */
}

@media screen and (max-width: 933px) {
  html {
    font-size: 62.5%;
  }

  nav {
    text-align: center;
  }

  #logo {
    padding: 1rem;
  }

  .book-details {
    margin-top: 4rem;
  }
}

.downloadLink {
  color: black; 
  text-decoration: none; 
}

.downloadLink:hover {
  text-decoration: underline;
}