/* Add to the end of /src/index.css */
.modal {
    position: absolute;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    left: 0;
    right: 0;
    top: 100px;
    border: .5px solid gray;
  }
  
  .container {
    margin: 0 auto;
    max-width: 900px;
  }